import React from "react";
import {
	AlertTriangle as _AlertTriangle,
	ArrowLeft as _ArrowLeft,
	ArrowRight as _ArrowRight,
	Award as _Award,
	Box as _Box,
	Calendar as _Calendar,
	Check as _Check,
	CheckCircle as _CheckCircle,
	ChevronDown as _ChevronDown,
	ChevronLeft as _ChevronLeft,
	ChevronRight as _ChevronRight,
	ChevronUp as _ChevronUp,
	ChevronsLeft as _ChevronsLeft,
	ChevronsRight as _ChevronsRight,
	CornerUpLeft as _CornerUpLeft,
	CornerUpRight as _CornerUpRight,
	CreditCard as _CreditCard,
	Delete as _Delete,
	ExternalLink as _ExternalLink,
	FileText as _FileText,
	Heart as _Heart,
	Home as _Home,
	IconProps,
	Lock as _Lock,
	MapPin as _MapPin,
	Menu as _Menu,
	Minus as _Minus,
	Plus as _Plus,
	RefreshCcw as _RefreshCcw,
	Repeat as _Repeat,
	RotateCcw as _RotateCcw,
	Search as _Search,
	ShoppingCart as _ShoppingCart,
	Sliders as _Sliders,
	Smartphone as _Smartphone,
	Star as _Star,
	Tool as _Tool,
	Truck as _Truck,
	User as _User,
	Users as _Users,
	X as _Close,
	Mail as _Mail,
	Package as _Package,
	ShoppingBag as _ShoppingBag,
	Slash as _Slash,
	Phone as _Phone,
	AlertCircle as _AlertCircle,
	Copy as _Copy,
	Info as _Info,
	ThumbsUp as _ThumbsUp,
	Zap as _Zap,
	ZoomIn as _ZoomIn,
	Sun as _Sun,
	Dribbble as _Dribbble,
	Droplet as _Droplet,
	Edit2 as _Edit,
	PlayCircle as _PlayCircle,
} from "react-feather";

import { colors } from "../../shared/styles/theme";
import IconEnglish from "./CustomIcons/IconEnglish.component";
import IconFrench from "./CustomIcons/IconFrench.component";
import IconSmartSize from "./CustomIcons/IconSmartSize.component";
import IconNotifyMe from "./CustomIcons/IconNotifyMe.component";
import IconBarCode from "./CustomIcons/IconBarCode.component";
import IconUserLoggedIn from "./CustomIcons/IconUserLoggedIn.component";
import IconToggle from "./CustomIcons/IconToggle.component";
import IconPickUpStore from "./CustomIcons/IconPickUpStore.component";
import IconFastDelivery from "./CustomIcons/IconFastDelivery.component";
import IconReturn from "./CustomIcons/IconReturn.component";
import IconQuestion from "./CustomIcons/IconQuestion.component";
import IconLinkedin from "./CustomIcons/IconLinkedin.component";
import IconFacebook from "./CustomIcons/IconFacebook.component";
import IconInstagram from "./CustomIcons/IconInstagram.component";
import IconYoutube from "./CustomIcons/IconYoutube.component";
import IconTikTok from "./CustomIcons/IconTikTok.component";
import IconBackToTop from "./CustomIcons/IconBackToTop.component";
import IconFulfillmentPickup from "@components/Icon/CustomIcons/IconFulfillmentPickup.component";
import IconFulfillmentDelivery from "@components/Icon/CustomIcons/IconFulfillmentDelivery.component";

import styles from "./Icon.module.scss";

const { black } = colors;

export enum EIcon {
	AlertTriangle = "alertTriangle",
	ArrowUp = "arrowUp",
	ArrowLeft = "arrowLeft",
	ArrowRight = "arrowRight",
	Award = "award",
	BarCode = "barCode",
	Box = "box",
	Plus = "plus",
	Calendar = "calendar",
	Cart = "cart",
	CheckCircle = "checkCircle",
	ChevronRight = "chevronRight",
	ChevronLeft = "chevronLeft",
	ChevronsRight = "chevronsRight",
	ChevronsLeft = "chevronsLeft",
	ChevronUp = "chevronUp",
	ChevronDown = "chevronDown",
	Close = "close",
	CornerUpLeft = "cornerUpLeft",
	CornerUpRight = "cornerUpRight",
	CreditCard = "creditCard",
	Droplet = "droplet",
	Delete = "delete",
	Dribbble = "dribbble",
	ExternalLink = "externalLink",
	English = "en",
	FastDelivery = "fastDelivery",
	French = "fr",
	FileText = "fileText",
	Heart = "heart",
	Home = "home",
	IconStar = "star",
	Lock = "lock",
	MapPin = "mapPin",
	Menu = "menu",
	NotifyMe = "notifyMe",
	PickUpStore = "pickUpStore",
	Question = "question",
	Repeat = "repeat",
	Return = "return",
	RotateCcw = "rotateCcw",
	ThumbsUp = "thumbsUp",
	Tool = "tool",
	Truck = "truck",
	User = "user",
	UserLoggedIn = "userLoggedIn",
	Users = "users",
	Facebook = "facebook",
	Youtube = "youtube",
	Instagram = "instagram",
	Linkedin = "linkedin",
	Tiktok = "tiktok",
	Minus = "minus",
	Check = "check",
	SmartPhone = "smartPhone",
	SmartSize = "smartSize",
	FulfillmentPickup = "fulfillmentPickup",
	FulfillmentDelivery = "fulfillmentDelivery",
	Search = "search",
	Sliders = "sliders",
	RefreshCcw = "refreshCcw",
	Mail = "mail",
	Package = "package",
	ShoppingBag = "shoppingBag",
	Slash = "slash",
	Sun = "sun",
	Phone = "phone",
	Toggle = "toggle",
	AlertCircle = "alertCircle",
	Copy = "copy",
	Info = "info",
	Zap = "zap",
	ZoomIn = "zoomIn",
	Edit = "edit",
	PlayCircle = "playCircle",
}

const icons = {
	arrowUp: IconBackToTop,
	arrowLeft: _ArrowLeft,
	arrowRight: _ArrowRight,
	award: _Award,
	barCode: IconBarCode,
	box: _Box,
	cart: _ShoppingCart,
	calendar: _Calendar,
	check: _Check,
	checkCircle: _CheckCircle,
	chevronRight: _ChevronRight,
	chevronLeft: _ChevronLeft,
	chevronsRight: _ChevronsRight,
	chevronsLeft: _ChevronsLeft,
	chevronUp: _ChevronUp,
	chevronDown: _ChevronDown,
	delete: _Delete,
	externalLink: _ExternalLink,
	close: _Close,
	cornerUpLeft: _CornerUpLeft,
	cornerUpRight: _CornerUpRight,
	creditCard: _CreditCard,
	en: IconEnglish,
	facebook: IconFacebook,
	fastDelivery: IconFastDelivery,
	fr: IconFrench,
	fileText: _FileText,
	heart: _Heart,
	home: _Home,
	instagram: IconInstagram,
	linkedin: IconLinkedin,
	tiktok: IconTikTok,
	mapPin: _MapPin,
	menu: _Menu,
	minus: _Minus,
	phone: _Phone,
	pickUpStore: IconPickUpStore,
	plus: _Plus,
	question: IconQuestion,
	repeat: _Repeat,
	return: IconReturn,
	rotateCcw: _RotateCcw,
	search: _Search,
	sliders: _Sliders,
	smartPhone: _Smartphone,
	smartSize: IconSmartSize,
	fulfillmentPickup: IconFulfillmentPickup,
	fulfillmentDelivery: IconFulfillmentDelivery,
	notifyMe: IconNotifyMe,
	star: _Star,
	tool: _Tool,
	truck: _Truck,
	user: _User,
	userLoggedIn: IconUserLoggedIn,
	users: _Users,
	youtube: IconYoutube,
	refreshCcw: _RefreshCcw,
	lock: _Lock,
	alertTriangle: _AlertTriangle,
	mail: _Mail,
	shoppingBag: _ShoppingBag,
	package: _Package,
	slash: _Slash,
	alertCircle: _AlertCircle,
	copy: _Copy,
	info: _Info,
	thumbsUp: _ThumbsUp,
	toggle: IconToggle,
	zap: _Zap,
	sun: _Sun,
	dribbble: _Dribbble,
	droplet: _Droplet,
	zoomIn: _ZoomIn,
	edit: _Edit,
	playCircle: _PlayCircle,
};

const fillIcons = [EIcon.English, EIcon.French];
const strokeIcons = [EIcon.UserLoggedIn];
export interface IIconExtendedProps extends IconProps {
	icon: EIcon | undefined;
	noHover?: boolean;
	noActive?: boolean;
	className?: string;
	cxEllipse?: string | number;
}

type IArgs = IIconExtendedProps & { isFillIcon: boolean; isStrokeIcon: boolean };

const generateClassName = ({
	className,
	icon,
	isFillIcon,
	noHover,
	noActive,
	isStrokeIcon,
}: IArgs) => {
	let wrapperClassName = `${className ?? ""}`;
	if (noHover || noActive || icon === EIcon.Cart) {
		return wrapperClassName;
	} else {
		wrapperClassName += ` ${styles.iconWrapper}`;
	}
	if (isFillIcon) {
		wrapperClassName += ` ${styles.fillIcon}`;
	}
	if (isStrokeIcon) {
		wrapperClassName += ` ${styles.strokeIcon}`;
	}
	return wrapperClassName;
};

const Icon = ({ icon, size, color, noHover, noActive, className, ...rest }: IIconExtendedProps) => {
	if (!icon) return null;

	const Component = icons[icon];
	const props = {
		size: size || 24,
		color: color || black,
		...rest,
	};

	const isFillIcon = fillIcons.includes(icon);
	const isStrokeIcon = strokeIcons.includes(icon);
	return (
		<span
			className={generateClassName({
				className,
				icon,
				isFillIcon,
				noHover,
				noActive,
				isStrokeIcon,
			})}
			data-testid="Icon_component"
		>
			<Component {...props} />
		</span>
	);
};

export default Icon;
