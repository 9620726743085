import { IconProps } from "react-feather";

const IconFulfillmentDelivery = ({ size, color }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			fill="none"
			data-testid="IconFulfillmentDelivery_svg"
		>
			<path
				xmlns="http://www.w3.org/2000/svg"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.9998 1.97192L12.4414 2.29369L11.9998 2.89987L11.5581 2.29369L11.9998 1.97192ZM11.9998 3.82844C12.1824 3.9619 12.4159 4.1327 12.6894 4.33301C13.3841 4.84194 14.3363 5.54126 15.3664 6.30293C17.4314 7.82976 19.7944 9.59607 21.0312 10.5855L21.9683 9.41422C20.7051 8.40367 18.3182 6.61998 16.2582 5.09681C15.2258 4.33348 14.2717 3.6328 13.5758 3.12298C13.2278 2.86805 12.9443 2.6608 12.7478 2.51725L12.4414 2.29369C12.4414 2.29365 12.4414 2.29369 11.9998 2.89987C11.5581 2.29369 11.5582 2.29365 11.5581 2.29369L11.2518 2.51725C11.0552 2.6608 10.7717 2.86805 10.4237 3.12298C9.72781 3.6328 8.77375 4.33348 7.74137 5.09681C5.68135 6.61998 3.29443 8.40367 2.03125 9.41422L2.96829 10.5855C4.20511 9.59607 6.56819 7.82976 8.63317 6.30293C9.66329 5.54126 10.6155 4.84194 11.3102 4.33301C11.5836 4.1327 11.8171 3.9619 11.9998 3.82844ZM4.04977 11.9999H5.54977V19.3999C5.54977 19.408 5.55028 19.4138 5.55084 19.4178C5.56428 19.4265 5.60929 19.4499 5.69977 19.4499H18.3998C18.4477 19.4499 18.4944 19.4303 18.5286 19.3988C18.539 19.3891 18.5457 19.3806 18.5498 19.3747V11.9999H20.0498V19.3999C20.0498 20.2695 19.2563 20.9499 18.3998 20.9499H5.69977C5.30492 20.9499 4.89865 20.8314 4.57781 20.5599C4.24449 20.2779 4.04977 19.8673 4.04977 19.3999V11.9999Z"
				fill={color}
				data-testid="IconFulfillmentDelivery_path"
			/>
		</svg>
	);
};
export default IconFulfillmentDelivery;
