import { IconProps } from "react-feather";

const IconFulfillmentPickup = ({ size, color }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			fill="none"
			data-testid="IconFulfillmentPickup_svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.7498 5.2251H22.0498V13.9251H21.9498V18.7751H20.4498V13.9251H16.7498V18.7251H2.0498V7.8251H6.7498V5.2251ZM6.7498 9.3251H3.5498V17.2251H6.7498V9.3251ZM8.2498 17.2251H15.2498V13.9251H8.2498V17.2251ZM8.2498 12.4251H20.5498V6.7251H8.2498V12.4251Z"
				fill={color}
				data-testid="IconFulfillmentPickup_path"
			/>
		</svg>
	);
};
export default IconFulfillmentPickup;
